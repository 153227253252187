import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';

export const StyledCardDetails = styled.div`
  ${rtl`
    .card-detail {
      &--border {
        border: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
          ${baseVariables.color.neutral20};
        border-radius: ${toRem(16)};
        box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
        margin-top: ${toRem(72)};
        @media ${baseVariables.mediaQuery.md} {
          margin-top: ${toRem(0)};
        }
      }
      &-wrapper {
        .points-underlined {
          font-family: ${baseVariables.font.fontSwissCondensed};
          border-bottom: ${toRem(5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.accent10};
          display: inline-block;
          width: fit-content;
          margin: ${toRem(4)} auto ${toRem(8)} auto;
          @media ${baseVariables.mediaQuery.md} {
            margin: ${toRem(8)} auto ${toRem(0)} auto;
            display: block;
          }
          @media ${baseVariables.mediaQuery.lg} {
            margin: 0;
            display: inline;
          }
        }
        .custom-link {
          display: block;
          @media ${baseVariables.mediaQuery.lg} {
            display: inline;
          }
        }
        .accordion-button {
          position: static;
        }
      }
    }
    // RTE styles goes here
    .middle-align {
      vertical-align: super;
      @media ${baseVariables.mediaQuery.md} {
        vertical-align: unset;
      }
      @media ${baseVariables.mediaQuery.lg} {
        vertical-align: super;
      }
    }
    .top-horizontal-border{
      border: none;
      @media ${baseVariables.mediaQuery.md} {
        padding-top: ${toRem(16)};
        display: block;
        border-top: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
        ${baseVariables.color.neutral30};
      }
    }
    .vertical-align{
      display:flex;
      align-items: center;
      justify-content: center;
      @media ${baseVariables.mediaQuery.md} {
        display: block;
      }
      @media ${baseVariables.mediaQuery.lg} {
        display:flex;
        align-items: center;
        justify-content: center;
      }
    }
    p:has(> span.t-subtitle-m) {
      margin-bottom: 8px;
    }
    p:has(> span.t-numbers-s) {
      margin-bottom: 16px;
    }
    .flex-align-center {
      display: flex;
      align-items: center;
    }
    :lang(ar-AE) {
      .accordion-button .m-accordion-title{
        display: none;
      }
    }
  `}
`;
